export default[
    {
        path: "/listado-enlaces",
        name: "listado-enlaces",
        component: () => import(/* webpackChunkName: "listado-enlaces" */ "../ListadoEnlaces.vue"),
    },
    {
        path: "/categorias",
        name: "categorias",
        component: () => import(/* webpackChunkName: "categorias" */ "../Categorias.vue"),
    },
    {
        path: "/configuracion",
        name: "configuracion",
        component: () => import(/* webpackChunkName: "categorias" */ "../Configuracion.vue"),
    },
    {
        path: "/catalogos",
        name: "catalogos",
        component: () => import(/* webpackChunkName: "categorias" */ "../Catalogos.vue"),
    },
    {
        path: "/usuarios",
        name: "usuarios",
        component: () => import(/* webpackChunkName: "usuarios" */ "../gestionUsuarios.vue"),
    },

]