import Vue from "vue";
import auth from './auth.services'
import users from './user.services'
import roles from './roles.services'
import profiles from './profiles.services'
import typeRoles from './typeRoles.services'
import paths from './path.services'
import Enlaces from "../views/qr/services/Enlaces.services";
import Categorias from "../views/qr/services/Categorias.services";
import firmas from "../services/firmas.services"
import funcionarios from "./funcionario.services"
import Catalogo from "../views/qr/services/Catalogo.services"
import Configuracion from "../views/qr/services/Configuracion.services"
import UsuariosServices from "../views/qr/services/Usuarios.services";

Vue.prototype.services = {
    auth,
    users,
    roles,
    profiles,
    typeRoles,
    paths,
    Enlaces,
    Categorias,
    firmas,
    funcionarios,
    Catalogo,
    Configuracion,
    UsuariosServices
}