import { http_client } from "../../../plugins/http_client";

const urlconfiguraciones = '/api/v1/usuario-configuracion'

const getConfiguracionId = async (id,body={}) => await http_client(`/api/v1/usuario-configuracion/${id}`,body,'get')
const putConfiguraciones = async(id,body)=> await http_client(`${urlconfiguraciones}/${id}`,body,'put')

export default{
    getConfiguracionId,
    putConfiguraciones
}