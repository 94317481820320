import { http_client } from "../../../plugins/http_client";

const getListadoEnlaces = async (params)=> await http_client('/api/v1/url', params ,'get');

const postNuevoRegistro = async(params)=> await http_client('/api/v1/url',params,'post',{"Content-Type": "multipart/form-data"});

const updateRegistro = async(params,formData)=> await http_client(`/api/v1/url/${params}`, formData ,'put',{"Content-Type": "multipart/form-data"});

const deleteRegistro = async(idenlace,params)=> await http_client(`/api/v1/url/${idenlace}`,params,'delete');

const getCaterorias = async(params) => await http_client('/api/v1/categoria',params,'get')

const getFiltroEnlaces= async(filtro)=>await http_client(`/api/v1/url?id_categoria=${filtro.categoria}&estado_url=${filtro.estado}`)



export default{
    getListadoEnlaces,
    postNuevoRegistro,
    updateRegistro,
    deleteRegistro,
    getCaterorias,
    getFiltroEnlaces
}