import { http_client } from "../../../plugins/http_client";


const getCatalogo = async(params = {})=> await http_client('/api/v1/catalogo-global',params,'get');

const postCatalogo = async(body)=> await http_client('/api/v1/catalogo-global',body,'post');

const deleteCatalogo = async(id)=> await http_client(`/api/v1/catalogo-global/${id}`,{},'delete')

const putCatalogo = async(params) => await http_client(`/api/v1/catalogo-global/${params.id}`,params,'put');

const getCatalogoGlobal = async (estado) =>  await http_client(`/api/v1/catalogo-global?estado=${estado}`);


export default{
    getCatalogo,
    postCatalogo,
    deleteCatalogo,
    putCatalogo,
    getCatalogoGlobal,
}