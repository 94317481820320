import Vue from "vue";
import VueRouter from "vue-router";
import rolesRouter from "../views/roles/rolesRouter";
import profilesRouter from "../views/profiles/profilesRouter";
import usersRouter from "../views/users/userRouter";
import pathsRouter from "../views/paths/pathRouter";
import EnlacesQrRouter from "../views/qr/router/EnlacesQrRouter"
import routeFuncionario from "../views/ValidadorQR/Funcionario/router/index"
import routeFirmaDigital from "../views/ValidadorQR/Firmas/router/index"


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
        children: [

            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import(/* webpackChunkName: "qr" */ "../views/dashboard"),
            },


            ...rolesRouter,
            ...profilesRouter,
            ...usersRouter,
            ...pathsRouter,
            ...EnlacesQrRouter,

            {
                path: "/security",
                name: "security",
                component: () => import(/* webpackChunkName: "qr" */ "../views/auth/security"),
            },
        ],
    },

    {
        path: "/home",
        name: "home",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/HomeValidador.vue"),
    },
    {
        path:"/enlace/:id",
        name:"enlace",
                component: () =>
            import(/* webpackChunkName: "categorias" */ "../views/qr/loadPage.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/auth/login"),
    },
    ...routeFuncionario,
    ...routeFirmaDigital,
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
            import(
                /* webpackChunkName: "recuperarPassword" */ "../views/auth/recoverPassword"
                ),
    },
    {
        path: "/reset-password/:id",
        name: "reset-password",
        component: () =>
            import(/* webpackChunkName: "resetPassword" */ "../views/auth/resetPassword"),
    },
    {
        path: "/verify-mail/:token",
        name: "verifyMail",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/auth/verifyMail"),
    },
    // {
    //     path:"/not_found",
    //     name:"not_found",
    //     component: () =>
    //         import(/* webpackChunkName: "forbidden" */ "../views/qr/error400.vue"),
    // },
    {
        path: "/forbidden",
        name: "Forbidden",
        component: () =>
            import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
    },
    {
        path: "/autenticacionqr",
        name: "2fa",
        component: () => import(/* webpackChunkName: "qr" */ "../views/auth/2fa"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach(async (to, from, next) => {
    if (to.fullPath === "/") {
        const token = localStorage.getItem("token");
        if (!token) {
            next("/home");
            return;
        }
    }
    if (await Vue.prototype.canNext(to)) {
        next();
    } else {
        next("/forbidden");
    }
});

export default router;
