import { http_client } from "../../../plugins/http_client";


const getCategorias = async(parametros)=> await http_client('/api/v1/categoria',parametros,'get');

const postCategorias = async(params) => await http_client('/api/v1/categoria',params,'post');

const putCategorias = async(params) => await http_client(`/api/v1/categoria/${params.id}`,params,'put');

const deleteCategorias = async(idcat,params)=> await http_client(`/api/v1/categoria/${idcat}`,params,'delete')

const QRcategoria = async(hash,param)=> await http_client(`/api/v1/url-activa/${hash}?decrypt_hash=true`,param,'get', {responseType: 'blob'}, 'blob', false)

const putConfiguracionCategoria = async (id, params) => await http_client(`/api/v1/configuracion-categoria/${id}`, params, 'put');

const getConfiguracionCategoria = async (id) => await http_client(`/api/v1/configuracion-categoria/${id}`, 'get');




const getCategoriaFiltro = async (estado) =>  await http_client(`/api/v1/categoria?estado=${estado}`);
  
  


export default{
    getCategorias,
    postCategorias,
    putCategorias,
    deleteCategorias,
    QRcategoria,
    putConfiguracionCategoria,
    getConfiguracionCategoria, 
    getCategoriaFiltro
}