<template>
  <v-app>
    <v-main class="pa-0 ma-0 myFont">
      <v-fade-transition>
        <v-snackbar
          v-model="alert().show"
          :value="alert().show"
          v-if="alert().show"
          :timeout="alert().timeout"
          top
          class="fixed"
          z-index="10"
          text
          tile
          elevation="1"
          :color="alert().type"
          right
        >
          <v-icon color="success" left v-if="alert().type === 'success'"
            >mdi-check-circle</v-icon
          >
          <v-icon color="error" left v-if="alert().type === 'error'"
            >mdi-alert-octagon</v-icon
          >
          <v-icon color="warning" left v-if="alert().type === 'warning'"
            >mdi-alert</v-icon
          >
          <v-icon color="info" left v-if="alert().type === 'info'"
            >mdi-information</v-icon
          >
          <span class="body-1"
            ><strong> {{ alert().message }}</strong></span
          >

          <template #action="{ attrs }">
            <v-btn v-bind="attrs" icon right @click="alert().show = false"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
          </template>
        </v-snackbar></v-fade-transition
      >
      
      <router-view :key="$route.fullPath"></router-view>


    </v-main>
    <v-footer  class="bg-footerApp esquinas" v-if="pie">
      <v-row class="pa-0 ma-0 d-flex flex-column flex-md-row">
        <v-col  cols="12" md="4"></v-col>
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <v-card color="rgb(255, 0, 0, 0.0)"  flat :width="whidth" >
            <v-img src="./assets/img/Logo_GOES2.png"   class="mt-14 "/>
          </v-card>
        </v-col>
        <v-col cols="12" md="4"  class="d-flex justify-center align-center">
          <div>
            <v-btn
            class="ma-2"
            text
            icon
            color="white"
            href="https://www.facebook.com/PresidenciaSV/"
            >
              <v-icon large>mdi-facebook</v-icon>
            </v-btn>

            <v-btn
            class="ma-2"
            text
            icon
            color="white"
            href="https://twitter.com/PresidenciaSV"
            >
              <v-icon large  >mdi-twitter</v-icon>
            </v-btn>
            <v-btn
              class="ma-2"
              text
              icon
              color="white"
              href="https://www.instagram.com/presidenciasv/"
            >
              <v-icon large>mdi-instagram</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-footer> 
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",

  data: () => ({
    pie:null,
    drawer: null,
    opciones: [
      {
        title: "Perfil",
        icon: "mdi-account",
      },
      {
        title: "Configuración",
        icon: "mdi-wrench-outline",
      },
      {
        title: "Cerrar Session",
        icon: "mdi-logout",
      },
    ],
  }),
  computed:{
    whidth(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
    }
  },
  methods: {
    ...mapGetters({
      alert: "utils/getAlert",
    }),
    footer(){
      const urlActual = window.location.href

      let newString = urlActual.split('/');

      if (newString.find(item => item === 'home') || newString.find(item => item === 'firma-electronica')|| newString.find(item => item === 'funcionario')) {
        this.pie = true;
      }else{
        this.pie = false;
      }
    }
  },

  updated() {
    this.footer()
  }
};
</script>

<style scoped>

.escudo{
    display: grid;
    justify-content: center;
}
.bg-footerApp {
  background-image: url("./assets/img/bg-footer.png") !important;
  background-size: cover !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  color: rgb(255, 0, 0, 0.0);
  

}

.esquinas{
  border-top-left-radius: 55px;
  border-top-right-radius: 55px;
  
  
}

</style>

