export  default[
    {
        path:`/firma-electronica/:hash`,
        name:"firma-electronica",
        component: () =>
        import(/* webpackChunkName: "firma-electronica" */ "../firmaDigital.vue"),
    },
    // {
    //     path:"/firma-electronica",
    //     name:"firma-electronica",
    //     component:()=>
    //         import(/*  webpackChunkName: "firma-electronica" */ "../HomeFirma.vue")
    // }
]