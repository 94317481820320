import { http_client } from "../plugins/http_client";

const token = {
    token:"1uSGVJ68pAxXfU8mpJ46CgnwiHpSvO7JXiy8INjpvHLdygVlFROTAp9pDKnxNfm7IpORIwV4UgePfZIEnquWG5hIqwHV5oRxYKviQa3OCi2BfHROMoStWWmfI44P7abNyRUFFgaw8sBUcej7zkyM2peAjoFv99BJEaGf9BIHt0iBw1vTvHtMUsT5UIFVfIOiDv1Bum7P"
}

//code_qr ="2270382"

const getfirmas = async(code_qr) => await http_client(process.env.VUE_APP_FIRMAS_API_URL,{code_qr},"post",token);

const getDocumento = async(code_qr) => await http_client(process.env.VUE_APP_FIRMA_DOCUMENT_API_URL,{code_qr}, "post", token,'blob');
export default{
    getfirmas,
    getDocumento
}