import { http_client } from "../../../plugins/http_client";

const getUsuarios = async (params = {}) => await http_client('/api/v1/gestion-usuario', params, 'get');
const postUsuarios = async (params = {}) => await http_client('/api/v1/gestion-usuario', params, 'post');
const putUsuarios = async (id_usuario, params = {}) => await http_client(`/api/v1/gestion-usuario/${id_usuario}`, params, 'put');
const suspenderUsuario = async (id_usuario, params = {}) => await http_client(`/api/v1/gestion-usuario/${id_usuario}`, params, 'delete');
const activarUsuario = async (id_usuario, params = {}) => await http_client(`/api/v1/gestion-usuario/restaurar/${id_usuario}`, params, 'put');
const getUsuario = async (id_usuario, params = {}) => await http_client(`/api/v1/gestion-usuario/${id_usuario}`, params, 'get');


export default {
    getUsuarios,
    postUsuarios,
    putUsuarios,
    suspenderUsuario,
    activarUsuario,
    getUsuario
}